@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold.98d58ed7.woff2") format("woff2"), url("IBMPlexMono-Bold.13c1f455.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Cyrillic.e292bd27.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Pi.35d298e7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin3.8cd5baff.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin2.2b1167c0.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin1.9c9bfd0a.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic.f8c55e60.woff2") format("woff2"), url("IBMPlexMono-BoldItalic.f7053c2c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Cyrillic.5d351e83.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Pi.72b5d6d7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin3.2fd8c4fe.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin2.409377e1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin1.191326bd.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight.eb9953b0.woff2") format("woff2"), url("IBMPlexMono-ExtraLight.ecd95465.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Cyrillic.4a65a8ce.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Pi.6287b2a9.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin3.47f51d66.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin2.cc79de2d.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin1.f699eb74.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic.a5b2c25a.woff2") format("woff2"), url("IBMPlexMono-ExtraLightItalic.3608e857.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Cyrillic.bf65d50a.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Pi.0d0716ff.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin3.a68f5729.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin2.b9b605f6.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin1.d8d8204f.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic.b75164d8.woff2") format("woff2"), url("IBMPlexMono-Italic.75c1aca4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Cyrillic.be42bccb.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Pi.9269f3b3.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin3.d367c01d.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin2.50b03094.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin1.98c81844.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light.5e9ef6d4.woff2") format("woff2"), url("IBMPlexMono-Light.c8ae537d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Cyrillic.c03be587.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Pi.c28ba431.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin3.8bbf29c9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin2.96925b8e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin1.9de3bb98.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic.66d0f95b.woff2") format("woff2"), url("IBMPlexMono-LightItalic.6f1a25f2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Cyrillic.88eca0c8.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Pi.6d790449.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin3.7588c8b0.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin2.eb595a50.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin1.c56e999b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium.576aef7b.woff2") format("woff2"), url("IBMPlexMono-Medium.683efde6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Cyrillic.2804884e.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Pi.dc0a9b9e.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin3.1602e11f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin2.f80dff9d.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin1.e9461dc1.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic.ef9052dc.woff2") format("woff2"), url("IBMPlexMono-MediumItalic.78cfbc1f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Cyrillic.ca68f091.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Pi.d0debd22.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin3.66d22df5.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin2.1d762585.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin1.4f867457.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular.7e1048be.woff2") format("woff2"), url("IBMPlexMono-Regular.a4829da2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Cyrillic.f2f03057.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Pi.2d6762c6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin3.d6c1e8df.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin2.5d66c701.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin1.e0bd9c6c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold.8c77c4c0.woff2") format("woff2"), url("IBMPlexMono-SemiBold.8bfa672f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Cyrillic.7a860126.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Pi.f80c5a3d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin3.a63f444f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin2.683d9ea1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin1.50cd6222.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic.36a096f4.woff2") format("woff2"), url("IBMPlexMono-SemiBoldItalic.d38a23f2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Cyrillic.4bf73841.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Pi.df6ec359.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin3.01613248.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin2.0de1c5bf.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin1.df253c9c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text.63ee186d.woff2") format("woff2"), url("IBMPlexMono-Text.0f10f286.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Cyrillic.0ccde514.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Pi.c9a4a4e6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin3.e6a91a73.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin2.d258d8a3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin1.8eaa0602.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic.458d3188.woff2") format("woff2"), url("IBMPlexMono-TextItalic.2da3a82c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Cyrillic.39763cc6.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Pi.39754995.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin3.4cf94c2e.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin2.4a8d4413.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin1.f7e97886.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin.ad0fe8ac.woff2") format("woff2"), url("IBMPlexMono-Thin.2074a8d6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Cyrillic.dfd9cc16.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Pi.1849f483.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin3.640551b8.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin2.ba7daf24.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin1.191a57cc.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic.9cb1b794.woff2") format("woff2"), url("IBMPlexMono-ThinItalic.9e39fa40.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Cyrillic.980e58e8.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Pi.9bd49655.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin3.88e5104b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin2.b04d55ce.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin1.27852435.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold.8e006283.woff2") format("woff2"), url("IBMPlexSans-Bold.02dc9a5c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Cyrillic.2ddc7527.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Pi.256cb137.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin3.48758889.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin2.943f2ece.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin1.2e3781d9.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Greek.4e850a81.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic.49543260.woff2") format("woff2"), url("IBMPlexSans-BoldItalic.002973a6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Cyrillic.fde2e17d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Pi.fa19856f.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin3.c20a13af.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin2.51bc8817.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin1.7e4dda9a.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Greek.292bce6f.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight.3b3fa991.woff2") format("woff2"), url("IBMPlexSans-ExtraLight.98e1532b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Cyrillic.e90f491d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Pi.30138b69.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin3.ced3e89a.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin2.f683f941.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin1.6ebecf98.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Greek.3df70010.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic.60114682.woff2") format("woff2"), url("IBMPlexSans-ExtraLightItalic.1b8c8ee7.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Cyrillic.33befd14.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Pi.6ad8b4bf.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin3.0a2138eb.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin2.cc9f877f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin1.3e94a992.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Greek.caa98308.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic.72436da9.woff2") format("woff2"), url("IBMPlexSans-Italic.66446a5e.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Cyrillic.2763591c.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Pi.0ad0278e.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin3.6f3b1532.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin2.81509fd9.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin1.70558afa.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Greek.21ad6bf1.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light.1ccc688a.woff2") format("woff2"), url("IBMPlexSans-Light.0331c514.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Cyrillic.f9c33659.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Pi.49a4ab16.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin3.787a6af3.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin2.9efa21bd.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin1.35919c22.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Greek.863d732e.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic.3d512b78.woff2") format("woff2"), url("IBMPlexSans-LightItalic.595ee92e.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Cyrillic.177f23d1.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Pi.f0efcbf7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin3.aa49018c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin2.bb9b5ecc.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin1.30ae4db6.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Greek.46060777.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium.34aa2c50.woff2") format("woff2"), url("IBMPlexSans-Medium.a583109f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Cyrillic.b11b425e.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Pi.ae952f19.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin3.e4479aa4.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin2.ff790f36.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin1.2ee50a76.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Greek.6e04a5f9.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic.4dee7412.woff2") format("woff2"), url("IBMPlexSans-MediumItalic.8150e543.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Cyrillic.cdd7ca0d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Pi.28f6acd6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin3.0cc4768a.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin2.61cd2daa.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin1.14830268.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Greek.03413212.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular.e81f1062.woff2") format("woff2"), url("IBMPlexSans-Regular.64fb7e60.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Cyrillic.f5d25fb9.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Pi.6f4998a4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin3.c8737f7b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin2.4fffadd5.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin1.114b8c54.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Greek.ee2c14d4.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold.65a27d0a.woff2") format("woff2"), url("IBMPlexSans-SemiBold.437469bf.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Cyrillic.85f5d83d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Pi.6d3cf286.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin3.47bcd61b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin2.3d537f74.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin1.f3988588.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Greek.61aa0eff.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic.95c7984a.woff2") format("woff2"), url("IBMPlexSans-SemiBoldItalic.e035a65d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Cyrillic.a263880e.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Pi.4cd98046.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin3.a1b02df6.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin2.7ea08a8e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin1.1b6bafda.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Greek.0d704da6.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text.9fe4d7c0.woff2") format("woff2"), url("IBMPlexSans-Text.3b13b6ff.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Cyrillic.657ece5d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Pi.6e95b894.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin3.66faae60.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin2.3429a310.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin1.25df9b68.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Greek.1ee01ca9.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic.f178fc6d.woff2") format("woff2"), url("IBMPlexSans-TextItalic.60c6b591.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Cyrillic.2cc89233.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Pi.2a0c86b8.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin3.d956e665.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin2.b6cae5b8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin1.6b737b24.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Greek.285dcf5b.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin.744cff26.woff2") format("woff2"), url("IBMPlexSans-Thin.8cc177ea.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Cyrillic.433310b4.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Pi.577dbe9b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin3.058a2ef5.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin2.820f2427.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin1.733c0c56.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Greek.85983444.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic.3cbae2fe.woff2") format("woff2"), url("IBMPlexSans-ThinItalic.84df13b2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Cyrillic.8db77031.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Pi.674f0910.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin3.28a454de.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin2.71282a67.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin1.b7e75d72.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Greek.05caeccd.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold.9e25269b.woff2") format("woff2"), url("IBMPlexSansCondensed-Bold.80002b53.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Pi.4d1af75c.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin3.0df65747.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin2.2f75c744.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin1.f8ab5e3e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic.fcc1f152.woff2") format("woff2"), url("IBMPlexSansCondensed-BoldItalic.9603e0d4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Pi.c99852a4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin3.00f19454.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin2.189612e2.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin1.86fddc1e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight.a4192834.woff2") format("woff2"), url("IBMPlexSansCondensed-ExtraLight.1ef95f19.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Pi.2f09c87d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin3.7e8da5e7.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin2.ad1d1186.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin1.67e64ead.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic.c3482a51.woff2") format("woff2"), url("IBMPlexSansCondensed-ExtraLightItalic.e31aa0a3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Pi.8cc59938.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin3.fc837420.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin2.2ef6a6ba.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin1.f454c7fb.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic.1a0e4c92.woff2") format("woff2"), url("IBMPlexSansCondensed-Italic.2c88bb05.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Pi.c5c81173.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin3.25094d2f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin2.8937916e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin1.8545b1cc.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light.393c7272.woff2") format("woff2"), url("IBMPlexSansCondensed-Light.7ae90f41.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Pi.79fdde43.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin3.bda84b40.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin2.264756b3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin1.308efc45.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic.bf8c66ec.woff2") format("woff2"), url("IBMPlexSansCondensed-LightItalic.469c52fe.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Pi.a4aea911.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin3.c4be8df4.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin2.b01a7264.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin1.a9c9cf6c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium.be3d0fd9.woff2") format("woff2"), url("IBMPlexSansCondensed-Medium.3d380820.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Pi.00f8c035.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin3.4ee319c9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin2.356e16d5.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin1.dbbc34ba.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic.51b7ee73.woff2") format("woff2"), url("IBMPlexSansCondensed-MediumItalic.f3b9e8c1.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Pi.ec42e86b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin3.3bb1a496.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin2.7498a259.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin1.b8644dce.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular.2e448e8d.woff2") format("woff2"), url("IBMPlexSansCondensed-Regular.789b3b1c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Pi.457ed915.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin3.fcf69881.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin2.e652eccf.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin1.bfc6ee7b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold.8d782f04.woff2") format("woff2"), url("IBMPlexSansCondensed-SemiBold.536252d2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Pi.67d7cca3.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin3.8b90f278.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin2.59c3effb.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin1.6ca79dfc.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic.1acaf18f.woff2") format("woff2"), url("IBMPlexSansCondensed-SemiBoldItalic.bc5c15b6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Pi.ddd8b2d6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin3.36329d7c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin2.84419259.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin1.8bec3b94.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text.289e60b2.woff2") format("woff2"), url("IBMPlexSansCondensed-Text.86a517c9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Pi.225f31d1.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin3.03869ee3.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin2.b5e83fa8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin1.c29fd99f.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic.fdf17159.woff2") format("woff2"), url("IBMPlexSansCondensed-TextItalic.fe7f5953.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Pi.61a9243d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin3.26cece0f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin2.24de1e8b.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin1.96f8417d.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin.893a8b8a.woff2") format("woff2"), url("IBMPlexSansCondensed-Thin.efe49bf9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Pi.b1fd2e13.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin3.33bceba3.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin2.af8e4fc3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin1.1b3088d6.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic.5f03e1a7.woff2") format("woff2"), url("IBMPlexSansCondensed-ThinItalic.56b96033.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Pi.bd5bfc29.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin3.ba509760.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin2.c824bf84.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin1.45fc8860.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Hebrew Bold), local(IBMPlexSansHebrew-Bold), url("IBMPlexSansHebrew-Bold.b7aa5eb3.woff2") format("woff2"), url("IBMPlexSansHebrew-Bold.a268146c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Hebrew ExtLt), local(IBMPlexSansHebrew-ExtLt), url("IBMPlexSansHebrew-ExtraLight.5860660d.woff2") format("woff2"), url("IBMPlexSansHebrew-ExtraLight.7a998d48.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Hebrew Light), local(IBMPlexSansHebrew-Light), url("IBMPlexSansHebrew-Light.762d8717.woff2") format("woff2"), url("IBMPlexSansHebrew-Light.2ba4fe4a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Hebrew Medm), local(IBMPlexSansHebrew-Medm), url("IBMPlexSansHebrew-Medium.488a0987.woff2") format("woff2"), url("IBMPlexSansHebrew-Medium.8cfe0121.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Hebrew), local(IBMPlexSansHebrew), url("IBMPlexSansHebrew-Regular.43795c23.woff2") format("woff2"), url("IBMPlexSansHebrew-Regular.e4d3abea.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Hebrew SmBld), local(IBMPlexSansHebrew-SmBld), url("IBMPlexSansHebrew-SemiBold.078571b2.woff2") format("woff2"), url("IBMPlexSansHebrew-SemiBold.5f46dfdd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Hebrew Text), local(IBMPlexSansHebrew-Text), url("IBMPlexSansHebrew-Text.5c1877f1.woff2") format("woff2"), url("IBMPlexSansHebrew-Text.7e98be03.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Hebrew Thin), local(IBMPlexSansHebrew-Thin), url("IBMPlexSansHebrew-Thin.160e8d74.woff2") format("woff2"), url("IBMPlexSansHebrew-Thin.00977c16.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Devanagari Bold), local(IBMPlexSansDevanagari-Bold), url("IBMPlexSansDevanagari-Bold.9a896c7c.woff2") format("woff2"), url("IBMPlexSansDevanagari-Bold.faf5d106.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Devanagari ExtLt), local(IBMPlexSansDevanagari-ExtLt), url("IBMPlexSansDevanagari-ExtraLight.6b90c38f.woff2") format("woff2"), url("IBMPlexSansDevanagari-ExtraLight.b2a0c884.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Devanagari Light), local(IBMPlexSansDevanagari-Light), url("IBMPlexSansDevanagari-Light.b8a33866.woff2") format("woff2"), url("IBMPlexSansDevanagari-Light.b78e822a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Devanagari Medm), local(IBMPlexSansDevanagari-Medm), url("IBMPlexSansDevanagari-Medium.2f879d53.woff2") format("woff2"), url("IBMPlexSansDevanagari-Medium.ea41974c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Devanagari), local(IBMPlexSansDevanagari), url("IBMPlexSansDevanagari-Regular.39960480.woff2") format("woff2"), url("IBMPlexSansDevanagari-Regular.427ede9d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Devanagari SmBld), local(IBMPlexSansDevanagari-SmBld), url("IBMPlexSansDevanagari-SemiBold.8b753353.woff2") format("woff2"), url("IBMPlexSansDevanagari-SemiBold.3ae35829.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Devanagari Text), local(IBMPlexSansDevanagari-Text), url("IBMPlexSansDevanagari-Text.4700d8d1.woff2") format("woff2"), url("IBMPlexSansDevanagari-Text.75badf10.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Devanagari Thin), local(IBMPlexSansDevanagari-Thin), url("IBMPlexSansDevanagari-Thin.d2da94db.woff2") format("woff2"), url("IBMPlexSansDevanagari-Thin.c444d248.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Thai Bold), local(IBMPlexSansThai-Bold), url("IBMPlexSansThai-Bold.5b7d170c.woff2") format("woff2"), url("IBMPlexSansThai-Bold.11eb94ee.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Thai ExtLt), local(IBMPlexSansThai-ExtLt), url("IBMPlexSansThai-ExtraLight.f3f31bee.woff2") format("woff2"), url("IBMPlexSansThai-ExtraLight.ee198cc0.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Thai Light), local(IBMPlexSansThai-Light), url("IBMPlexSansThai-Light.bfd9e8cb.woff2") format("woff2"), url("IBMPlexSansThai-Light.c9365cd4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Thai Medm), local(IBMPlexSansThai-Medm), url("IBMPlexSansThai-Medium.292e5672.woff2") format("woff2"), url("IBMPlexSansThai-Medium.12d1ba44.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Thai), local(IBMPlexSansThai), url("IBMPlexSansThai-Regular.1c257754.woff2") format("woff2"), url("IBMPlexSansThai-Regular.3a0e6ae6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Thai SmBld), local(IBMPlexSansThai-SmBld), url("IBMPlexSansThai-SemiBold.9257fb03.woff2") format("woff2"), url("IBMPlexSansThai-SemiBold.0b076870.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Thai Text), local(IBMPlexSansThai-Text), url("IBMPlexSansThai-Text.743bd17c.woff2") format("woff2"), url("IBMPlexSansThai-Text.7d1ac78f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thai Thin), local(IBMPlexSansThai-Thin), url("IBMPlexSansThai-Thin.5014f662.woff2") format("woff2"), url("IBMPlexSansThai-Thin.aa115c0d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Thai Looped Bold), local(IBMPlexSansThaiLooped-Bold), url("IBMPlexSansThaiLooped-Bold.0c667b0e.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Bold.64d2022a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Thai Looped ExtLt), local(IBMPlexSansThaiLooped-ExtLt), url("IBMPlexSansThaiLooped-ExtraLight.e6895009.woff2") format("woff2"), url("IBMPlexSansThaiLooped-ExtraLight.f4843a8b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Thai Looped Light), local(IBMPlexSansThaiLooped-Light), url("IBMPlexSansThaiLooped-Light.e664f1b1.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Light.599610d9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Thai Looped Medm), local(IBMPlexSansThaiLooped-Medm), url("IBMPlexSansThaiLooped-Medium.d8cd35da.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Medium.73d6ee47.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Thai Looped), local(IBMPlexSansThaiLooped), url("IBMPlexSansThaiLooped-Regular.70479912.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Regular.2712c8aa.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Thai Looped SmBld), local(IBMPlexSansThaiLooped-SmBld), url("IBMPlexSansThaiLooped-SemiBold.d03fc0a8.woff2") format("woff2"), url("IBMPlexSansThaiLooped-SemiBold.05282d06.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Thai Looped Text), local(IBMPlexSansThaiLooped-Text), url("IBMPlexSansThaiLooped-Text.720313fe.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Text.fd895f67.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thai Looped Thin), local(IBMPlexSansThaiLooped-Thin), url("IBMPlexSansThaiLooped-Thin.61c9fa52.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Thin.77700e6a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold.b30b3cdf.woff2") format("woff2"), url("IBMPlexSerif-Bold.da439ade.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Cyrillic.ffe1cd4e.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Pi.f4ce8895.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin3.ba04ad96.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin2.52afe2a9.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin1.9427a646.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic.4c747408.woff2") format("woff2"), url("IBMPlexSerif-BoldItalic.b2557cbd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Cyrillic.0edcac42.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Pi.75672d20.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin3.ccaf2ad0.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin2.6fc929a3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin1.7108d983.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight.b13550c3.woff2") format("woff2"), url("IBMPlexSerif-ExtraLight.78058c9e.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Cyrillic.7fca2ad3.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Pi.b3a6048a.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin3.a9defe36.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin2.eaea4b84.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin1.e3fbe96b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic.fe5b3415.woff2") format("woff2"), url("IBMPlexSerif-ExtraLightItalic.ffac731b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Cyrillic.74792108.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Pi.c9a1c03d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin3.2efecf40.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin2.a11159d3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin1.a067acb1.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic.e7c795ec.woff2") format("woff2"), url("IBMPlexSerif-Italic.61512925.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Cyrillic.51421333.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Pi.86e66eac.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin3.b69aeb33.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin2.67eb29aa.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin1.5a4e806c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light.4802aadf.woff2") format("woff2"), url("IBMPlexSerif-Light.3eaff4fd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Cyrillic.46f7d1fd.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Pi.6a074e52.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin3.3cb8b404.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin2.1af949d3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin1.f685e14e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic.a6481be0.woff2") format("woff2"), url("IBMPlexSerif-LightItalic.72b877a4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Cyrillic.bfb73ac3.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Pi.c87309d5.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin3.05aaaf50.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin2.2ebe7ef2.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin1.4533b9e4.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium.5a734569.woff2") format("woff2"), url("IBMPlexSerif-Medium.8de450a8.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Cyrillic.61e63123.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Pi.0067b857.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin3.c7744122.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin2.c9789d3f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin1.cf4b3451.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic.3856ff63.woff2") format("woff2"), url("IBMPlexSerif-MediumItalic.1093ac95.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Cyrillic.15a717e8.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Pi.a1efd4e6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin3.fcc680ba.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin2.53d59178.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin1.5d5201c2.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular.a89cb1bd.woff2") format("woff2"), url("IBMPlexSerif-Regular.0df0e8e9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Cyrillic.d1fd1958.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Pi.63a8bdc6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin3.fb8f53d1.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin2.35a36cc0.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin1.c10c465b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold.4c5d40a4.woff2") format("woff2"), url("IBMPlexSerif-SemiBold.8a5a13a7.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Cyrillic.b1d0b3d6.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Pi.5868dc4b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin3.cf16cea7.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin2.97d96adb.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin1.1f424eeb.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic.812b6b31.woff2") format("woff2"), url("IBMPlexSerif-SemiBoldItalic.f25950d2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Cyrillic.860cf2d5.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Pi.821ef1ba.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin3.bd4be85a.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin2.f1c44ff8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin1.ea034493.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text.7660c698.woff2") format("woff2"), url("IBMPlexSerif-Text.3dc16258.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Cyrillic.e01f0c2c.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Pi.3c85c79a.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin3.eae6fc95.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin2.8b1f4839.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin1.8abcfe94.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic.54865ac7.woff2") format("woff2"), url("IBMPlexSerif-TextItalic.138a09c3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Cyrillic.6a504b78.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Pi.241112b4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin3.7f2105f6.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin2.6e5f3daa.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin1.c7928a0e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin.e804954e.woff2") format("woff2"), url("IBMPlexSerif-Thin.7aab65c3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Cyrillic.4d60de92.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Pi.ca0a0641.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin3.ab31f602.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin2.b02e6ef8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin1.de4b43c3.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic.2f4855d3.woff2") format("woff2"), url("IBMPlexSerif-ThinItalic.86b6c8af.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Cyrillic.b51f5140.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Pi.00ac44a4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin3.74cf26e8.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin2.9ac0e49a.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin1.ec950327.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Arabic Bold), local(IBMPlexSansArabic-Bold), url("IBMPlexSansArabic-Bold.234dfccf.woff2") format("woff2"), url("IBMPlexSansArabic-Bold.ea2917e7.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Arabic ExtLt), local(IBMPlexSansArabic-ExtLt), url("IBMPlexSansArabic-ExtraLight.52379bf7.woff2") format("woff2"), url("IBMPlexSansArabic-ExtraLight.d104029d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Arabic Light), local(IBMPlexSansArabic-Light), url("IBMPlexSansArabic-Light.3da8e700.woff2") format("woff2"), url("IBMPlexSansArabic-Light.475e4a73.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Arabic Medm), local(IBMPlexSansArabic-Medm), url("IBMPlexSansArabic-Medium.7ec84491.woff2") format("woff2"), url("IBMPlexSansArabic-Medium.5670bcb0.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Arabic), local(IBMPlexSansArabic), url("IBMPlexSansArabic-Regular.a5be6b76.woff2") format("woff2"), url("IBMPlexSansArabic-Regular.7ff46b14.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Arabic SmBld), local(IBMPlexSansArabic-SmBld), url("IBMPlexSansArabic-SemiBold.0b72b505.woff2") format("woff2"), url("IBMPlexSansArabic-SemiBold.7c1e9204.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Arabic Text), local(IBMPlexSansArabic-Text), url("IBMPlexSansArabic-Text.292dc2eb.woff2") format("woff2"), url("IBMPlexSansArabic-Text.914fc0b4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Arabic Thin), local(IBMPlexSansArabic-Thin), url("IBMPlexSansArabic-Thin.bc7d8fed.woff2") format("woff2"), url("IBMPlexSansArabic-Thin.bcedc3a9.woff") format("woff");
}

:root {
  --main: #f24f00;
  --secondary: #737373;
  --bg: #010302;
  --team-member-outer-width: 18rem;
  --team-member-inner-width: calc(.88 * var(--team-member-outer-width));
  --team-member-inner-offset: calc((var(--team-member-outer-width)  - var(--team-member-inner-width)) / 2);
  --team-member-width: calc(var(--team-member-outer-width)  + 1 * var(--team-member-inner-offset));
}

body {
  color: var(--secondary);
  text-align: center;
  background-color: #fff;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  overflow-x: hidden;
}

body, ul {
  margin: 0;
}

ul {
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
  list-style-type: none;
  display: flex;
}

ul li {
  margin: 0;
}

a.button {
  background-color: var(--bg);
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10rem;
  margin: 2rem 0;
  padding: .6rem 2rem;
  font-size: 1.2rem;
  text-decoration: none;
}

a.button:hover {
  color: var(--main);
  -webkit-text-decoration: 1px underline var(--main);
  text-decoration: 1px underline var(--main);
  background-color: #fff;
}

h1 {
  font-size: 2.6rem;
}

h1, h2 {
  border-top: 7px solid var(--main);
  max-width: 100vw;
}

h2 {
  font-size: 2.5rem;
}

a[href^="mailto:"] {
  color: #fff;
}

a[href^="mailto:"]:hover {
  color: var(--secondary);
  background-color: #fff;
}

address {
  font-style: normal;
}

address, address > p {
  margin: 0;
}

section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

header {
  z-index: 10;
  pointer-events: none;
  width: 100%;
  position: fixed;
}

header nav {
  pointer-events: auto;
  text-transform: uppercase;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding: 2vw;
  display: flex;
}

header nav #logo {
  display: grid;
}

header nav #logo > img {
  pointer-events: auto;
  background-size: contain;
  grid-area: 1 / 1;
  width: 100%;
  max-height: 15vh;
  animation-name: heartBeat;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

header nav ul {
  text-align: right;
  justify-content: flex-end;
}

header nav ul li {
  margin: 0 .4rem;
  font-size: 1.5rem;
}

header nav ul li a {
  pointer-events: auto;
  color: var(--bg);
  text-align: right;
  text-decoration: none;
}

header nav ul li a:hover {
  color: var(--main);
  background-color: var(--bg);
}

header nav ul li.on-dark-section a {
  color: #fff;
}

header nav ul li.on-dark-section a:hover {
  color: var(--main);
}

section#section-main {
  background-image: url("bg_top_cutout.fda31101.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  box-shadow: 0 0 30px #7d7d7d61;
}

section#section-main div#time-and-place {
  text-transform: uppercase;
  flex-direction: column;
  font-size: 1.5rem;
  display: flex;
  position: absolute;
  bottom: 10vh;
}

section#section-main div#time-and-place time {
  color: var(--secondary);
  font-size: 2.5rem;
  text-decoration: none;
}

section#section-main div#time-and-place address, section#section-main div#time-and-place span {
  letter-spacing: .4rem;
  margin: .3rem 0 .3rem .5rem;
}

section#section-main > svg {
  max-width: 12vw;
  height: 2.5rem;
  animation-name: slideInDown;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: 1vh;
}

div.bg-colors {
  z-index: -1;
  background-position: center;
  background-size: 30rem;
  width: 100%;
  height: 100vh;
  position: fixed;
}

section#section-claim {
  background-color: #fff;
  padding: 2rem;
}

section#section-claim p#main-claim {
  max-width: 70rem;
  margin: 1rem;
  font-size: 1.7rem;
}

section#section-startovac {
  background-image: url("pattern.f7ff6cdb.png");
  padding: 2rem;
}

section#section-startovac p {
  max-width: 70rem;
  margin: 1rem;
  font-size: 1.7rem;
}

section#section-countdown {
  color: var(--bg);
  text-transform: uppercase;
  background-color: #fff;
  margin: .5rem 0;
  padding: 1.5rem;
}

section#section-countdown span {
  letter-spacing: 1.2rem;
  margin-left: 1.2rem;
}

section#section-countdown span#countdown-title {
  letter-spacing: .3rem;
  margin: 0 0 0 .3rem;
  font-size: 1.3rem;
}

section#section-countdown span#days {
  font-size: 3.9rem;
}

section#section-countdown span#hours {
  font-size: 1.8rem;
}

section#section-countdown span#minutes {
  font-size: 1.5rem;
}

section#section-lineup {
  background-color: var(--bg);
  color: #fff;
  flex-flow: wrap;
  align-items: flex-start;
  padding: 2rem 0;
}

section#section-lineup div.lineup {
  flex-direction: column;
  justify-content: center;
  margin: 2rem 3rem;
  font-size: 1.5rem;
  display: inline;
}

section#section-lineup div.lineup h2 {
  display: inline;
}

section#section-lineup div.lineup div.lineup-entry {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

section#section-lineup div.lineup div.lineup-entry span {
  text-align: left;
  margin: .5rem;
}

div#sponsors {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding: 3rem 1rem;
}

div#sponsors img.sponsor {
  object-fit: contain;
  width: auto;
  max-width: 8rem;
  max-height: 15vh;
  margin: 1rem 2rem;
}

footer div#sponsors .sponsor-kudyznudy {
  flex-basis: 100%;
  padding-bottom: 2rem;
}

footer div#sponsors .sponsor-kudyznudy img {
  max-height: 9rem;
}

footer div#sponsors .sponsor-cez {
  max-width: 15rem !important;
}

footer div#sponsors img.sponsor {
  margin: 1rem;
}

section#section-artists {
  background-color: #fff;
  background-image: url("pattern.f7ff6cdb.png");
  flex-direction: column;
  justify-content: center;
  padding: 0 8vw 4rem;
  display: flex;
}

section#section-artists div#artists {
  flex-flow: wrap;
  justify-content: center;
  min-width: 20rem;
  max-width: 43rem;
  display: flex;
}

section#section-artists div#artists a {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

section#section-artists div#artists a picture, section#section-artists div#artists a img {
  width: 100%;
}

section#section-gallery-teaser {
  background: url("gallery-teaser-bg.eb61bc1d.jpg") 0 0 / cover no-repeat;
  padding: 0 10vw;
  box-shadow: 0 0 30px #0000005e;
}

section#section-gallery-teaser h2 {
  color: #fff;
}

section#section-gallery-teaser div#gallery {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

section#section-gallery-teaser div#gallery a {
  background-color: var(--main);
  justify-content: center;
  align-items: center;
  width: 25%;
  min-width: 20rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

section#section-gallery-teaser div#gallery a picture {
  transition: all .3s ease-in-out;
  transform: scale(1.2);
}

section#section-gallery-teaser div#gallery a img {
  width: 100%;
}

section#section-gallery-teaser div#gallery a span {
  text-transform: uppercase;
  opacity: 0;
  color: #000;
  mix-blend-mode: screen;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #fff;
  border-radius: 10rem;
  margin: 0;
  padding: .6rem 2rem;
  position: absolute;
}

section#section-gallery-teaser div#gallery a:hover picture {
  opacity: .6;
  transform: scale(1.08);
}

section#section-gallery-teaser div#gallery a:hover span {
  opacity: 1;
  transition: all .3s;
}

section#section-gallery {
  background-color: #efefef;
  background-image: url("bg_top.1e2fdd06.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 20vh;
}

section#section-gallery div#gallery {
  width: 70vw;
  min-height: 100vh;
}

section#section-gallery div#gallery h1, section#section-gallery div#gallery h2 {
  display: inline-block;
}

section#section-program {
  background-color: #efefef;
  background-image: url("bg_top.1e2fdd06.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20vh .3em 0;
}

section#section-program div#program {
  flex-flow: wrap;
  place-content: center;
  width: 100%;
  max-width: 65em;
  margin-bottom: 1.5em;
  display: flex;
}

section#section-team {
  background-color: #efefef;
  background-image: url("bg_top.1e2fdd06.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 20vh;
}

section#section-team div#team {
  max-width: calc(5 * var(--team-member-width));
  flex-flow: wrap;
  place-content: center;
  width: 100%;
  margin-bottom: 1.5em;
  display: flex;
}

section#section-team div#team div.team-member {
  width: var(--team-member-width);
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
}

section#section-team div#team div.team-member img {
  object-fit: contain;
  width: 100%;
}

section#section-team div#team div.team-member span {
  margin: .5rem;
}

section#section-team div#team div.team-member span.member-description {
  margin: 0;
  font-size: .8rem;
}

section#section-team div#team div.team-member div.outer {
  width: var(--team-member-outer-width);
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

section#section-team div#team div.team-member img.frame {
  position: absolute;
}

section#section-team div#team div.team-member img.inner {
  left: var(--team-member-inner-offset);
  width: var(--team-member-inner-width);
  top: var(--team-member-inner-offset);
  height: var(--team-member-inner-width);
  position: relative;
}

section#section-icons {
  background-color: #fff;
  flex-flow: wrap;
  justify-content: space-around;
  padding: 3rem 15vw;
}

section#section-icons div {
  flex-direction: column;
  align-items: center;
  width: 12rem;
  display: flex;
}

section#section-icons div img {
  max-height: 5rem;
  margin: 1rem;
}

section#section-icons div span {
  text-transform: uppercase;
}

section#section-location > div#location {
  background: url("kudyknam_bg.b4737ab4.jpg") bottom / cover no-repeat;
}

section#section-location {
  flex-flow: wrap;
  padding: 0;
}

section#section-location > div {
  flex: 1;
  height: 25rem;
}

section#section-location > div#location {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

section#section-location > div#location div#transport-ways {
  font-size: 1.3rem;
}

section#section-location > div#location div#transport-ways div.transport-way {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

section#section-location > div#location div#transport-ways div.transport-way div.transport-way-icon {
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
}

section#section-location > div#location div#transport-ways div.transport-way div.transport-way-icon svg {
  width: 100%;
  height: 75%;
}

section#section-location > div#location div#transport-ways div.transport-way svg {
  height: 1rem;
}

section#section-location > div#location div#transport-ways div.transport-way p {
  margin: 0 1rem;
}

section#section-location > div#location address {
  margin: 3rem;
}

section#section-location > div#map {
  background-color: #fff;
}

ul#social-list {
  z-index: 10;
  flex-direction: row;
  display: flex;
  position: fixed;
  bottom: 0;
}

ul#social-list > li {
  padding: .5rem;
}

ul#social-list > li a svg {
  fill: var(--bg);
  width: 3rem;
  max-width: 12vw;
}

ul#social-list > li a svg.on-dark-section path {
  fill: #fff;
}

ul#social-list > li:hover {
  background-color: var(--bg);
}

ul#social-list > li:hover svg, ul#social-list > li:hover svg.on-dark-section path {
  fill: var(--main);
}

footer {
  border-top: 7px solid var(--main);
  margin-top: .5rem;
}

footer div#footer-info {
  background-color: var(--secondary);
  color: #fff;
  padding: 4rem 0;
}

footer div#footer-info div#emails {
  flex-direction: column;
  display: inline-flex;
}

footer div#footer-info p {
  margin: 1rem;
}

.bg-colors-loaded {
  background-image: url("pattern_colors.1499c8b2.png");
}

img.logo-img-hidden {
  visibility: hidden;
}

footer div#sponsors {
  box-sizing: border-box;
  background-color: #fff;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1rem;
  display: flex;
}

footer div#sponsors .sponsor {
  object-fit: contain;
  width: auto;
  max-width: 8rem;
  max-height: 7rem;
  margin: 1rem 2rem;
}

[hidden] {
  display: none;
}

@keyframes heartBeat {
  82.5% {
    transform: scale(1);
  }

  86% {
    transform: scale(1.02);
  }

  89.5% {
    transform: scale(1);
  }

  93% {
    transform: scale(1.02);
  }

  to {
    transform: scale(1);
  }
}

@keyframes slideInDown {
  25% {
    transform: translateZ(0);
  }

  50% {
    visibility: visible;
    transform: translate3d(0, -.7rem, 0);
  }

  75% {
    transform: translateZ(0);
  }
}

@media only screen and (max-width: 750px) {
  header > nav ul li a {
    background-color: #fff;
  }

  header > nav ul li a:hover {
    color: var(--main);
    background-color: var(--bg);
  }

  header > nav ul li.on-dark-section a {
    background-color: var(--main);
  }

  header > nav ul li.on-dark-section a:hover {
    color: var(--main);
    background-color: #fff;
  }

  section#section-main div#countdown {
    position: relative;
    bottom: 10vh;
  }

  section#section-main div#time-and-place {
    font-size: 1rem;
    bottom: 13vh;
  }

  section#section-main div#time-and-place address, section#section-main div#time-and-place span {
    line-height: .8rem;
  }

  section#section-main div#time-and-place time {
    font-size: 2rem;
  }

  section#section-icons {
    flex-direction: column;
  }

  section#section-lineup div.lineup {
    margin: 2rem 1rem;
  }

  section#section-claim p#main-claim {
    font-size: 1.5rem;
  }

  section#section-gallery div#gallery {
    width: 90vw;
  }

  section#section-gallery-teaser {
    padding: 0;
  }

  section#section-team {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  section#section-location > div {
    width: 100%;
  }

  section#section-location {
    flex-direction: column;
  }

  ul#social-list > li {
    padding: .2rem;
  }
}

nav .nav-lg {
  display: inline;
}

nav .nav-sm {
  display: none;
}

nav ul {
  line-height: 1.5;
}

@media screen and (max-width: 600px) {
  nav .nav-sm-enabled .nav-lg {
    display: none;
  }

  nav .nav-sm-enabled .nav-sm {
    display: inline;
  }
}

/*# sourceMappingURL=galerie.7d832199.css.map */
